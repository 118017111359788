@use 'mixins' as *;

.hero-block {
  position: relative;

  &--margin {
    margin-block-end: var(--spacing-regular);
  }
}

.grid {
  --grid-gutter: 20px;

  inline-size: 100%;
}

.grid-item {
  grid-column: 1 / span 12;

  &--wide {
    grid-column: 1 / span 12;
  }

  @include breakpoint-min(medium) {
    grid-column: 2 / span 10;
  }
}

.hero-block-wrapper {
  overflow: hidden;
  position: relative;
  block-size: auto;
  inline-size: 100%;
  background: var(--color-gray5);
  background-image: url('/svg/triangle.svg');
  background-repeat: no-repeat;
  background-size: auto 125%;
  background-position: right bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-block-size: calc(100vh - 120px);
  color: var(--color-black);
  border-block-end: none;

  @include portrait {
    aspect-ratio: 1/1;
  }

  @include landscape {
    aspect-ratio: 12/5;
  }

  @include breakpoint-min(small) {
    min-block-size: auto;
    block-size: auto;
    color: var(--color-white);
  }

  &--image {
    max-block-size: unset;
    color: var(--color-white);
    border-block-end: thin solid var(--color-gray5);
    z-index: 1;
  }

  &--thin {
    @include landscape {
      aspect-ratio: 32 / 9;
    }
  }

  &--gradient {
    &::before {
      content: '';
      position: absolute;
      inset-inline-start: 0;
      inset-block-end: 0;
      inline-size: 100%;
      block-size: 70%;
      background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
      z-index: 1;
    }
  }
}

.hero-block-inner {
  position: relative;
  z-index: 1;
  max-inline-size: var(--content-max-width);
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

  &--left {
    align-items: flex-start;
    justify-content: flex-end;
    text-align: inherit;
  }

  @include breakpoint-min(small) {
    padding-inline: var(--spacing-regular);
    padding-block: calc(var(--spacing-regular) * 2);
  }

  svg {
    margin-block-end: var(--spacing-regular);
    color: var(--color-workwear-yellow);
    fill: var(--color-workwear-yellow);
  }
}

h1.title,
h2.title {
  text-shadow: none;
  max-inline-size: 38ch;
  color: var(--color-workwear-black);
  font-family: inherit;
  letter-spacing: 0.2px;
  font-weight: var(--font-weight-medium);

  &--margin {
    margin-block: 0 var(--spacing-tinier);
  }

  &--shadow {
    text-shadow: 0 0 1rem var(--color-workwear-black);
    color: var(--color-white);
  }

  &--wide {
    max-inline-size: auto;
  }

  &--with-icon {
    position: relative;
    font-family: var(--font-mikro);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-header2-content);
    line-height: var(--line-height-header4);
    margin-block: 27px 1rem;
    padding-block-start: 34px;
    z-index: 1;
    padding-inline: var(--spacing-regular);

    @include breakpoint-min(small) {
      color: var(--color-white);
      line-height: 3.125rem;
      margin-inline-start: 48px;
      padding-block-start: 0;
      padding-inline: unset;

      &::before {
        inset-block-start: -45px;
        inset-inline-start: -30px;
      }
    }
  }

  @include breakpoint-min(small) {
    font-size: var(--font-size-h1);
  }
}

p.sub-title {
  font-size: var(--font-size-body);
  max-inline-size: 54ch;
  margin-block: var(--spacing-xx-small) var(--spacing-regular);

  &--image {
    color: var(--color-white);
  }
}

.description-wrapper {
  &--with-line {
    position: relative;
    inline-size: 100%;

    &::before {
      content: '';
      display: block;
      block-size: 100%;
      inline-size: 100vw;
      background-color: var(--color-white);
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;

      @include breakpoint-min(small) {
        display: none;
      }
    }

    @include breakpoint-min(small) {
      inline-size: auto;
      margin-inline-start: unset;
      padding: unset;
      background-color: transparent;
    }
  }
}

p.description {
  font-family: var(--font-fakt-pro);
  font-size: var(--font-size-body);
  text-shadow: none;
  max-inline-size: 60ch;
  margin-block: var(--spacing-xx-small) var(--spacing-regular);

  &--shadow {
    text-shadow: 0 0 1rem var(--color-workwear-black);
    color: var(--color-white);
  }

  &--with-line {
    position: relative;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.5px;
    line-height: 26px;
    text-shadow: none;
    color: var(--color-workwear-black);
    margin-block: var(--spacing-regular);
    margin-inline: 62px var(--spacing-regular);

    &::before {
      content: '';
      display: block;
      block-size: 1px;
      inline-size: 27px;
      background-color: var(--color-workwear-black);
      position: absolute;
      inset-block-start: 11px;
      inset-inline-start: -42px;

      @include breakpoint-min(small) {
        background-color: var(--color-white);
        inset-inline-start: -48px;
      }
    }

    @include breakpoint-min(small) {
      color: var(--color-white);
      font-size: var(--font-size-header3);
      line-height: var(--line-height-header2-content);
      margin: 0 0 48px 50px;
      text-shadow: 0 0 0.333rem var(--color-workwear-black);
    }
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-tiny);

  @include breakpoint-min(small) {
    gap: 30px;
  }

  @include breakpoint-min(tiny) {
    flex-direction: row;
  }
}

.content-box-small {
  position: relative;
  align-self: flex-start;
  inline-size: 85%;
  max-inline-size: 65ch;
  min-block-size: 200px;
  inset-block-end: 0;
  margin-block-start: -5rem;
  box-sizing: border-box;
  display: flex;
  padding: 3rem 1.5rem;
  align-items: center;
  line-height: 24px;
  z-index: 1;

  p {
    margin: 0;
  }

  @include breakpoint-min(small) {
    inline-size: 600px;
    padding: 5rem;
    min-block-size: 256px;
    margin-block-start: -7rem;
  }

  @media screen and (width >= 1480px) {
    margin-inline-start: calc(50vw - 720px - 5rem);
  }
}

.content-box-big {
  position: relative;
  align-self: flex-start;
  min-block-size: 60px;
  background-color: white;
  padding-inline-start: 16px;
  padding-block-start: 35px;
  margin-block: -60px var(--spacing-medium);
  z-index: 1;
  margin-inline-end: 16px;

  @include breakpoint-min(small) {
    padding-inline-start: calc(16px + 8.2vw);
    margin-inline-end: calc(16px + 8.2vw);
  }

  @include breakpoint-min(medium) {
    padding-inline: calc(16px + 17.25vw) 5vw;
    margin-inline-end: calc(16px + 12.25vw);
    margin-block-start: -180px;
    min-block-size: 180px;
  }

  @include breakpoint-min(large) {
    padding-inline: calc(16px + 25.3vw) 5vw;
    margin-inline-end: calc(16px + 20.3vw);
  }

  @media screen and (width >= 1500px) {
    padding-inline: calc(50vw - 362px) 5vw;
    margin-inline-end: calc(50vw - 355px - 5vw);
  }
}

.guide-button-container {
  position: absolute;
  inset-block-start: -48px;
  inset-inline-end: 0;
}

.guide-button {
  align-items: center;
  appearance: none;
  background-color: var(--color-workwear-yellow);
  border-radius: 0;
  border: 0;
  box-sizing: border-box;
  color: var(--color-workwear-black);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-m);
  font-family: var(--font-mikro);
  block-size: 48px;
  line-height: 48px;
  padding: 0 var(--spacing-small);
  padding-inline: 24px;
  text-align: end;
  text-transform: uppercase;
  transition: 0.2s;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-workwear-yellow2);
    border-color: var(--color-workwear-yellow2);
  }

  svg {
    position: static;
    display: flex;
    margin-inline-start: 8px;
    inset-block-start: unset;
    transform: none;
    inline-size: 12px;
    block-size: 12px;
  }
}

h1.content-box-title,
h2.content-box-title {
  font-size: var(--font-size-header2-content);
  font-weight: var(--font-weight-bold);
  margin-block-end: var(--spacing-tiny);
  margin-inline-end: 16px;

  @include breakpoint-min(medium) {
    font-size: var(--font-size-h2);
    margin-block-end: 40px;
  }
}

.content-box-description {
  font-family: var(var(--font-fakt-pro));
  font-size: var(--font-size-header4);
  line-height: 26px;
  font-weight: var(--font-weight-medium);
  margin-block: 0 20px;

  @include breakpoint-min(medium) {
    letter-spacing: 0.5px;
    margin-block-end: 0;
  }
}
