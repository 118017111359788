.footer {
  --footer-bg-color: var(--color-workwear-black, #232120);
  --footer-font-family-heading: var(--font-hero);
  --footer-font-family-regular: var(--font-regular);
  --footer-font-weight-bold: var(--font-weight-medium, 400);
  --footer-font-weight-regular: var(--font-weight-normal, 300);
  --footer-heading-font-size: var(--font-size-header3, 1.25rem);
  --footer-heading-line-height: var(--line-height-header2-content, 28px);
  --footer-logo-color: var(--color-white, #fff);
  --footer-logo-size: var(--spacing-medium, 48px);
  --footer-spacing-l: var(--spacing-medium, 48px);
  --footer-spacing-small: var(--spacing-x-small, 16px);
  --footer-text-color-hover: var(--color-gray4, #f2f2f2);
  --footer-text-color: var(--color-white, #fff);
}

.logo {
  position: relative;
  block-size: 48px;
  inline-size: 48px;
}
