@mixin overlay($active: false) {
  position: fixed;
  content: '';
  background-color: var(--color-overlay-transparent, rgb(35 33 32 / 30%));
  inset: 0;
  pointer-events: none;
  inset-block-start: var(--overlay-block-start, 0);

  @if $active {
    visibility: visible;
    opacity: 1;
  } @else {
    visibility: hidden;
    opacity: 0;
  }
}
