@use 'mixins' as *;

.video-block {
  background-color: var(--color-gray4);
  position: relative;
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-player {
  block-size: calc(100vh - var(--header-height));

  --media-object-fit: cover;
  --media-object-position: center;
  --controls: none;
  --dialog: none; /* Hide the error dialog */
}

.content-container {
  position: absolute;
  gap: 32px;
  display: flex;
  flex-direction: column;
  max-inline-size: calc(90vw);
  z-index: 2;

  @include breakpoint-min(small) {
    max-inline-size: calc(75vw);
  }
}

.title {
  text-transform: uppercase;
  text-align: center;
  color: var(--color-white);
  font-weight: var(--font-weight-thick);
  text-shadow: rgb(35 33 32) 0 0 1rem;
  letter-spacing: 1px;
  font-family: var(--font-mikro);

  &-h1 {
    font-size: var(--font-size-h1-mobile);
    line-height: var(--line-height-header1);

    @include breakpoint-min(small) {
      font-size: var(--font-size-h1);
    }

    &-h2 {
      font-size: var(--font-size-h2-mobile);
      line-height: var(--line-height-header2);

      @include breakpoint-min(small) {
        font-size: var(--font-size-h2);
      }
    }
  }
}

.link-button {
  inline-size: auto;
  block-size: auto;
  align-self: center;
}

.mute-button {
  position: absolute;
  inline-size: 50px;
  background: none;
  border: none;
  inset-inline-end: 20px;
  inset-block-end: 20px;
  color: var(--mux-player-icon-color, #000);
  cursor: pointer;
  z-index: 10;

  @include hover {
    svg circle {
      color: #393939;
    }
  }
}

.overlay {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background: var(
    --color-overlay-gradient,
    linear-gradient(360deg, #000 0%, rgb(0 0 0 / 0%) 62.14%)
  );
}
