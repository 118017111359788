@use 'mixins' as *;

.root {
  --navigation-menu-bg-color: var(--color-white);
  --navigation-menu-font-weight: var(--font-weight-medium);
  --navigation-menu-font-size: var(--font-size-body);
  --navigation-menu-letter-spacing: 0.5px;
  --navigation-menu-text-color: var(--color-workwear-black);
  --navigation-menu-hover-color: var(--color-gray1);
  --navigation-menu-link-padding: var(--spacing-x-small) var(--spacing-tiny);
  --navigation-menu-padding: 3rem;
  --grid-columns: 8;
  --overlay-block-start: 57px;

  &.overlay::before {
    @include overlay(true);
  }
}

.navigation-menu-trigger,
.navigation-menu-link,
.active {
  &::after {
    content: '';
    position: absolute;
    block-size: 2px;
    inline-size: 16px;
    inset-block-end: 8px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background: rgb(94 90 89);
    border-radius: 2px;
    visibility: hidden;
  }

  @include hover {
    &::after {
      visibility: visible;
    }
  }
}

.navigation-menu-link.active {
  &::after {
    visibility: visible;
  }
}

.first-col-list {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-small);
}

.columns-list {
  display: flex;
  flex-flow: column nowrap;
  padding-inline: 0;
  text-align: start;
  list-style: none;
}

ul.columns-list {
  gap: var(--spacing-tiny);
}

a.sub-item-menu-link,
a.cast-item-menu-link {
  padding: 4px;
}

a.sub-item-menu-link {
  font-weight: var(--font-weight-normal);
}
